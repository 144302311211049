<template>
  <div>
    <template v-if="type === $options.GeoSitemapTypes.Home">
      <UcscTabs
        v-if="$utilities.sections"
        v-model="currentSection"
        no-body
        @input="onTabsInput"
      >
        <UcscTabItem
          v-for="item of $utilities.sections"
          :key="item.slug"
          :value="item.slug"
        >
          <template #header>
            <h3 class="ucsc-tabs__itemHeader-text">
              {{ item.label }}
            </h3>
          </template>
        </UcscTabItem>
      </UcscTabs>
    </template>

    <UcscTabs
      v-if="type === $options.GeoSitemapTypes.Listing"
      v-model="currentTypology"
      no-body
    >
      <UcscTabItem
        v-for="typology in typologies"
        :key="typology.slug"
        :value="typology.id"
      >
        <template #header>
          <span class="ucsc-tabs__itemHeader-text">{{ typology.name }}</span>
        </template>
      </UcscTabItem>
    </UcscTabs>
    <div class="columns-2 md:columns-3 lg:columns-4 xl:columns-6">
      <template v-if="$fetchState.pending">
        <OSkeleton
          v-for="(item, index) in 120"
          :key="index"
          class="columns-auto"
          :root-class="`h-14 pb-6 ${index % 5 ? 'w-56' : 'w-72'}`"
          item-class="h-full"
          :rounded="false"
        />
      </template>
      <template v-else>
        <div
          v-for="(region, regionName) in cities"
          :key="regionName"
          class="break-inside-avoid-column mb-10"
        >
          <h4 class="font-bold text-lg">
            {{ regionName }}
          </h4>

          <ul>
            <li
              v-for="(province, provinceName) in region"
              :key="provinceName"
              class="mt-2"
            >
              <NuxtLink
                :to="
                  province.link +
                  (typologiesIndex && currentTypology
                    ? `/${typologiesIndex[currentTypology].slug}`
                    : '')
                "
                class="w-fit inline-block text-blue underline focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue"
              >
                {{ provinceName }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// Static assets
import { computeTopTypologies } from '~/assets/js/listing'
import { Sections, SectionsMap } from '~/assets/js/sections'

// Components
import UcscTabItem from '~/components/UcscTabItem'
import UcscTabs from '~/components/UcscTabs.vue'

const Home = 'home'
const Listing = 'listing'
const GeoSitemapTypes = {
  Home,
  Listing,
}

export default {
  components: {
    UcscTabs,
    UcscTabItem,
  },
  props: {
    type: {
      type: String,
      default: GeoSitemapTypes.Listing,
    },

    section: {
      type: String,
      default: Sections.VenditaImmobili,
    },
  },

  data() {
    return {
      currentSection: this.$route.params?.section || Sections.VenditaImmobili,
      currentTypology: '',
      cities: null,
      typologies: null,
    }
  },
  async fetch() {
    const { type, currentSection, currentTypology } = this

    const res = await this.$axios.get('/_api/api/v2/properties/cities', {
      params: {
        section: SectionsMap[currentSection],
        ...(currentTypology ? { typology: currentTypology } : {}),
      },
    })

    const { cities: citiesData, typologies: typologiesData } = res.data

    if (type === GeoSitemapTypes.Listing && typologiesData) {
      this.typologies = computeTopTypologies(currentSection, typologiesData)
    }

    const cities = {}

    for (const it of citiesData) {
      const { region, province, properties_count: count } = it

      if (!cities[region.name]) {
        cities[region.name] = {}
      }

      if (!cities[region.name][province.name]) {
        cities[region.name][province.name] = {
          link: `/${currentSection}/${province.abbr.toLowerCase()}`,
          count: 0,
        }
      }

      cities[region.name][province.name].count += count
    }

    this.cities = cities
  },

  GeoSitemapTypes,

  computed: {
    typologiesIndex() {
      const { typologies } = this

      if (!typologies) {
        return
      }

      const typologiesIndex = typologies.reduce((set, it) => {
        set[it.id] = it
        return set
      }, {})

      return typologiesIndex
    },
  },

  watch: {
    currentSection() {
      this.currentTypology = ''
      this.$fetch()
    },

    currentTypology(currentTypology) {
      if (!currentTypology) {
        return
      }

      this.$fetch()
    },

    section: {
      immediate: true,
      handler(section) {
        this.currentSection = section
      },
    },
  },

  methods: {
    onTabsInput(item) {
      const section = this.$utilities.sections.find((it) => it.slug === item)
      const sectionLabel = section.label

      this.$tealium.track({
        event_name: 'tab_click',
        scheda_tipo: sectionLabel,
        page_trigger: 'scheda_annuncio_tab',
        link_name: 'tab_click',
        event_type: 'tab_click',
      })
    },
  },
}
</script>

<style lang="postcss">
@import '~/assets/css/components/tabs';
</style>
