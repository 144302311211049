<template>
  <div
    class="ucsc-homeCard"
    :class="{
      'ucsc-homeCard--hasImage': img,
    }"
    :style="style"
  >
    <div
      class="ucsc-homeCard__inner"
      :class="{
        'ucsc-homeCard__inner--row': layout === 'row',
      }"
    >
      <SvgIcon
        v-if="icon"
        aria-hidden="true"
        :name="icon"
        :class="[
          iconColor,
          {
            'max-w-9.6 max-h-9.6 mr-8': layout === 'row',
            'max-w-19 max-h-19 mb-8': layout === 'col',
          },
        ]"
      />
      <div
        class="flex-1"
        :class="{
          'text-white': theme === 'light',
        }"
      >
        <div
          v-if="hasSlot('heading')"
          class="ucsc-homeCard__title"
          :class="`ucsc-homeCard__title--${titleSize}`"
        >
          <slot name="heading"></slot>
        </div>
        <slot></slot>
        <div
          v-if="hasSlot('description')"
          class="ucsc-homeCard__description"
          :class="{ 'ucsc-homeCard__description--clamp': descriptionClamp }"
        >
          <slot name="description"></slot>
        </div>
        <div
          v-if="linkText && linkHref"
          class="flex items-center"
          :class="[linkClass, img ? 'mt-8' : 'mt-4']"
        >
          <NuxtLink
            class="ucsc-homeCard__link"
            :class="{ 'ucsc-homeCard__link--white': theme === 'light' }"
            :to="linkHref"
            :aria-label="ariaLabel"
          >
            {{ linkText }}
            <SvgIcon
              :name="
                theme === 'light' ? 'angle-right-white' : 'angle-right-blue'
              "
              class="max-h-5 max-w-5 ml-4"
            />
          </NuxtLink>
        </div>
      </div>
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ariaLabel: {
      type: String,
      default: '',
    },
    layout: {
      type: String,
      default: 'col',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    titleSize: {
      type: String,
      default: 'medium',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    img: {
      type: String,
      default: '',
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    linkHref: {
      type: [String, Object],
      default: '',
    },
    linkText: {
      type: String,
      default: '',
    },
    linkClass: {
      type: String,
      default: '',
    },
    descriptionClamp: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    style() {
      const { img } = this

      if (!img) {
        return
      }

      const computedImg = this.$img(img)

      return this.gradient
        ? `background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.75)), url(${computedImg})`
        : `background-image: url(${computedImg})`
    },
  },

  methods: {
    hasSlot(slot) {
      return !!this.$slots[slot]
    },
  },
}
</script>

<style lang="postcss" scoped>
.ucsc-homeCard {
  &__inner {
    @apply flex flex-col;

    &--row {
      @apply flex-row;
    }
  }

  &--hasBorder {
    @apply p-10 border-2 border-solid border-black-light rounded-md;
  }

  &--hasImage {
    @apply rounded-md p-10 bg-cover bg-no-repeat;
  }

  &__linkWrapper {
    @apply flex items-center flex-1 mt-10;
  }

  &__link {
    @apply flex items-center text-navitem font-bold uppercase text-blue mb-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue;
    &--white {
      @apply text-white;
    }
  }

  &__title {
    @apply block font-bold;

    &--medium {
      @apply text-lg uppercase;
    }

    &--large {
      @apply text-xl min-h-56 max-h-56 overflow-hidden;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  &__description {
    @apply text-base mt-2;
    &--clamp {
      @apply block min-h-36 max-h-36 overflow-hidden;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }
}
</style>
