var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ucsc-homeCard",class:{
    'ucsc-homeCard--hasImage': _vm.img,
  },style:(_vm.style)},[_c('div',{staticClass:"ucsc-homeCard__inner",class:{
      'ucsc-homeCard__inner--row': _vm.layout === 'row',
    }},[(_vm.icon)?_c('SvgIcon',{class:[
        _vm.iconColor,
        {
          'max-w-9.6 max-h-9.6 mr-8': _vm.layout === 'row',
          'max-w-19 max-h-19 mb-8': _vm.layout === 'col',
        },
      ],attrs:{"aria-hidden":"true","name":_vm.icon}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1",class:{
        'text-white': _vm.theme === 'light',
      }},[(_vm.hasSlot('heading'))?_c('div',{staticClass:"ucsc-homeCard__title",class:`ucsc-homeCard__title--${_vm.titleSize}`},[_vm._t("heading")],2):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.hasSlot('description'))?_c('div',{staticClass:"ucsc-homeCard__description",class:{ 'ucsc-homeCard__description--clamp': _vm.descriptionClamp }},[_vm._t("description")],2):_vm._e(),_vm._v(" "),(_vm.linkText && _vm.linkHref)?_c('div',{staticClass:"flex items-center",class:[_vm.linkClass, _vm.img ? 'mt-8' : 'mt-4']},[_c('NuxtLink',{staticClass:"ucsc-homeCard__link",class:{ 'ucsc-homeCard__link--white': _vm.theme === 'light' },attrs:{"to":_vm.linkHref,"aria-label":_vm.ariaLabel}},[_vm._v("\n          "+_vm._s(_vm.linkText)+"\n          "),_c('SvgIcon',{staticClass:"max-h-5 max-w-5 ml-4",attrs:{"name":_vm.theme === 'light' ? 'angle-right-white' : 'angle-right-blue'}})],1)],1):_vm._e()],2),_vm._v(" "),_vm._t("end")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }