import { partner as propertyPartner } from './property'
import { unitsKeys } from '~/assets/js/mappings'

function cardFeatures() {
  if (!this.property) {
    return
  }

  const features = this.property.features
  const category = this.property.building.category.name

  return [
    ...(category === 'Residenziale' || category === 'Commerciale'
      ? [
          ...(features.surface
            ? [
                `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(features.rooms ? [`${features.rooms} locali`] : []),
        ]
      : category === 'Terreni'
      ? [
          ...(features.surface
            ? [
                `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(features.land_morphology
            ? [this.$utilities.capitalize(features.land_morphology)]
            : []),
        ]
      : category === 'Cantieri'
      ? [
          ...(features.surface_min
            ? [
                `da ${this.$utilities.formatNumber(features.surface_min)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(features.rooms_min ? [`da ${features.rooms_min} locali`] : []),
        ]
      : []),
  ]
}

function features() {
  const category = this.property.building.category.name
  const typology = this.property.building.property_type.name
  const features = this.property.features
  const extraFeatures = this.property.extra_features

  return [
    ...(category === 'Residenziale'
      ? [
          ...(features.surface
            ? [
                `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(extraFeatures.bathrooms
            ? [
                `${extraFeatures.bathrooms} ${
                  extraFeatures.bathrooms > 1 ? 'bagni' : 'bagno'
                }`,
              ]
            : []),
          ...(features.rooms ? [`${features.rooms} locali`] : []),
          ...(features.energy_class_pending
            ? ['In attesa C.E.']
            : features.energy_class_exempt
            ? [
                'Esente cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>',
              ]
            : features.energy_class
            ? [
                `${features.energy_class} cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>`,
              ]
            : []),
        ]
      : category === 'Commerciale'
      ? [
          ...(features.surface
            ? [
                `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(features.rooms ? [`${features.rooms} locali`] : []),
          ...(extraFeatures.toilets
            ? [
                `${extraFeatures.toilets} ${
                  extraFeatures.toilets > 1 ? 'bagni' : 'bagno'
                }`,
              ]
            : []),
          ...(features.energy_class_pending
            ? ['In attesa C.E.']
            : features.energy_class_exempt
            ? [
                'Esente cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>',
              ]
            : features.energy_class
            ? [
                `${features.energy_class} cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>`,
              ]
            : []),
        ]
      : category === 'Terreni'
      ? [
          ...(features.surface
            ? [
                `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          this.$utilities.capitalize(
            typology.toLowerCase().replace('terreno ', '')
          ),
          ...(features.land_morphology
            ? [this.$utilities.capitalize(features.land_morphology)]
            : []),
        ]
      : category === 'Cantieri'
      ? [
          ...(features.surface_min
            ? [
                `da ${this.$utilities.formatNumber(features.surface_min)} ${
                  unitsKeys[features.surface_unit]
                }`,
              ]
            : []),
          ...(features.rooms_min ? [`da ${features.rooms_min} locali`] : []),
          ...(features.energy_class_pending
            ? ['In attesa C.E.']
            : features.energy_class_exempt
            ? [
                'Esente cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>',
              ]
            : features.energy_class
            ? [
                `${features.energy_class} cl. <span class="lg:hidden">en</span><span class="hidden lg:inline">energetica</span>`,
              ]
            : []),
        ]
      : []),
  ]
}

function extraFeatures() {
  const category = this.property.building.category.name
  const location = this.property.location
  const features = this.property.features
  const extraFeatures = this.property.extra_features

  const extraFeaturesInCommon = [
    {
      label: 'Rif',
      value: this.property.id,
    },
    {
      label: 'Contratto',
      value: this.property.transaction.contract
        ? this.$utilities.capitalize(this.property.transaction.contract.name)
        : 'Vendita',
    },
    ...(location.locality.thoroughfare.show_address
      ? [
          {
            label: 'Indirizzo',
            value: `${location.locality.thoroughfare.address}${
              location.locality.thoroughfare.show_address_number
                ? ' ' + location.locality.thoroughfare.address_number
                : ''
            }`,
          },
        ]
      : []),
    ...(location.locality.neighbourhood
      ? [
          {
            label: 'Quartiere',
            value: location.locality.neighbourhood,
          },
        ]
      : []),
    ...(location.locality.postal_code
      ? [
          {
            label: 'Cap',
            value: location.locality.postal_code,
          },
        ]
      : []),
  ]

  const computedExtraFeatures = [
    ...(category === 'Residenziale'
      ? [
          ...(extraFeatures.floor
            ? [
                {
                  label: 'Piano',
                  value: `${extraFeatures.floor}${
                    extraFeatures.num_floors
                      ? ' di ' + extraFeatures.num_floors
                      : ''
                  }`,
                },
              ]
            : []),
          ...(extraFeatures.living_rooms ||
          extraFeatures.other_rooms ||
          extraFeatures.bedrooms
            ? [
                {
                  label: 'Locali',
                  value: this.$utilities.residentialTotalRooms(extraFeatures),
                },
              ]
            : []),
          ...(extraFeatures.heating
            ? [
                {
                  label: 'Riscaldamento',
                  value: extraFeatures.heating,
                },
              ]
            : []),
          {
            label: 'Fibra',
            value: extraFeatures.fiber ? 'Sì' : 'No',
          },
          {
            label: 'Ascensore',
            value: extraFeatures.elevator ? 'Sì' : 'No',
          },
          {
            label: 'Balconi',
            value: extraFeatures.balcony_count
              ? `${extraFeatures.balcony_count} ${
                  extraFeatures.balcony_count === 1 ? ' balcone' : ' balconi'
                }`
              : 'No',
          },
          {
            label: 'Terrazzi',
            value: extraFeatures.terrace_count
              ? `${extraFeatures.terrace_count} ${
                  extraFeatures.terrace_count === 1 ? ' terrazzo' : ' terrazzi'
                }${
                  extraFeatures.terrace_surface
                    ? ', ' +
                      this.$utilities.formatNumber(
                        extraFeatures.terrace_surface
                      ) +
                      ' ' +
                      unitsKeys[extraFeatures.terrace_unit]
                    : ''
                }`
              : 'No',
          },
          {
            label: 'Stato manutenzione',
            value: extraFeatures.maintenance_state,
          },
          {
            label: 'Box auto',
            value: extraFeatures.car_box ? extraFeatures.car_box : 'No',
          },
          ...(extraFeatures.private_garden !== 'unspecified'
            ? [
                {
                  label: 'Giardino privato',
                  value: this.$utilities.privateGardenValue(
                    extraFeatures.private_garden,
                    extraFeatures.private_garden_surface,
                    extraFeatures.private_garden_unit,
                    'No',
                    'Sì'
                  ),
                },
              ]
            : []),
          {
            label: 'Giardino condominiale',
            value: extraFeatures.has_public_garden
              ? `Sì${
                  extraFeatures.public_garden_surface
                    ? ', ' +
                      this.$utilities.formatNumber(
                        extraFeatures.public_garden_surface
                      ) +
                      ' ' +
                      unitsKeys[extraFeatures.public_garden_unit]
                    : ''
                }`
              : 'No',
          },
        ]
      : category === 'Commerciale'
      ? [
          ...(extraFeatures.floor
            ? [
                {
                  label: 'Piano',
                  value: `${extraFeatures.floor}${
                    extraFeatures.num_floors
                      ? ' di ' + extraFeatures.num_floors
                      : ''
                  }`,
                },
              ]
            : []),
          {
            label: 'Locali',
            value: features.rooms,
          },
          ...(extraFeatures.heating
            ? [
                {
                  label: 'Riscaldamento',
                  value: extraFeatures.heating,
                },
              ]
            : []),
          {
            label: 'Ascensore',
            value: extraFeatures.elevator ? 'Sì' : 'No',
          },
          {
            label: 'Stato manutenzione',
            value: extraFeatures.maintenance_state,
          },
          ...(extraFeatures.private_garden !== 'unspecified'
            ? [
                {
                  label: 'Giardino privato',
                  value: this.$utilities.privateGardenValue(
                    extraFeatures.private_garden,
                    extraFeatures.private_garden_surface,
                    extraFeatures.private_garden_unit,
                    'No',
                    'Sì'
                  ),
                },
              ]
            : []),
          ...(extraFeatures.has_parking
            ? [
                {
                  label: 'Parcheggio',
                  value: `Sì${
                    extraFeatures.parking_surface
                      ? ', ' +
                        this.$utilities.formatNumber(
                          extraFeatures.parking_surface
                        ) +
                        ' ' +
                        unitsKeys[extraFeatures.parking_unit]
                      : ''
                  }`,
                },
              ]
            : []),
        ]
      : category === 'Terreni'
      ? [
          {
            label: "Cambio destinazione d'uso",
            value: extraFeatures.change_of_intended_use ? 'Sì' : 'No',
          },
          ...(features.land_morphology
            ? [
                {
                  label: 'Morfologia territorio',
                  value: this.$utilities.capitalize(features.land_morphology),
                },
              ]
            : []),
          ...(extraFeatures.arable
            ? [
                {
                  label: 'Seminativo',
                  value: extraFeatures.arable_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.arable_surface
                      )} ${unitsKeys[extraFeatures.arable_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.wooded
            ? [
                {
                  label: 'Boschivo',
                  value: extraFeatures.wooded_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.wooded_surface
                      )} ${unitsKeys[extraFeatures.wooded_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.irrigated
            ? [
                {
                  label: 'Irriguo',
                  value: extraFeatures.irrigated_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.irrigated_surface
                      )} ${unitsKeys[extraFeatures.irrigated_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.stable_meadows
            ? [
                {
                  label: 'Prati stabili',
                  value: extraFeatures.stable_meadows_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.stable_meadows_surface
                      )} ${unitsKeys[extraFeatures.stable_meadows_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.orchard
            ? [
                {
                  label: 'Frutteto',
                  value: extraFeatures.orchard_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.orchard_surface
                      )} ${unitsKeys[extraFeatures.orchard_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.viticulture
            ? [
                {
                  label: 'Vitivinicolo',
                  value: extraFeatures.viticulture_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.viticulture_surface
                      )} ${unitsKeys[extraFeatures.viticulture_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          ...(extraFeatures.other_usage
            ? [
                {
                  label: 'Altro uso',
                  value: extraFeatures.other_usage_surface
                    ? `${this.$utilities.formatNumber(
                        extraFeatures.other_usage_surface
                      )} ${unitsKeys[extraFeatures.other_usage_unit]}`
                    : 'Sì',
                },
              ]
            : []),
          {
            label: 'Volume edificabile',
            value: extraFeatures.building_volume
              ? `${this.$utilities.roundNumber(
                  (extraFeatures.building_volume / features.surface) * 100,
                  1
                )}%`
              : 'n.d.',
          },
          {
            label: 'Volume max edificabile',
            value: extraFeatures.building_volume_max
              ? `${this.$utilities.roundNumber(
                  (extraFeatures.building_volume_max / features.surface) * 100,
                  1
                )}%`
              : 'n.d.',
          },
        ]
      : category === 'Cantieri'
      ? [
          ...(features.rooms_min
            ? [
                {
                  label: 'Locali',
                  value: `da ${features.rooms_min}`,
                },
              ]
            : []),
          ...(extraFeatures.num_floors
            ? [
                {
                  label: 'Piani',
                  value: extraFeatures.num_floors,
                },
              ]
            : []),
          ...(extraFeatures.heating
            ? [
                {
                  label: 'Riscaldamento',
                  value: extraFeatures.heating,
                },
              ]
            : []),
          {
            label: 'Stato manutenzione',
            value: extraFeatures.construction_state,
          },
        ]
      : []),
  ]

  return extraFeaturesInCommon.concat(computedExtraFeatures)
}

function partner() {
  const partner = propertyPartner(this.property)

  return partner
}

function pdfFeatures() {
  const category = this.property.building.category.name
  const typology = this.property.building.property_type.name
  const features = this.property.features
  const extraFeatures = this.property.extra_features

  return [
    ...(category === 'Residenziale'
      ? [
          {
            label: 'm<sup>2</sup>',
            value: features.surface
              ? `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`
              : 'n.d.',
            icon: 'area',
          },
          {
            label: 'n. locali',
            value: features.rooms ? features.rooms : 'n.d.',
            icon: 'rooms',
          },
          {
            label: 'n. bagni',
            value: extraFeatures.bathrooms ? extraFeatures.bathrooms : 'n.d.',
            icon: 'bathroom',
          },
          {
            label: 'n. balconi/terrazzi',
            value: `${
              extraFeatures.balcony_count ? extraFeatures.balcony_count : 'n.d.'
            } / ${
              extraFeatures.terrace_count ? extraFeatures.terrace_count : 'n.d.'
            }`,
            icon: 'balcony',
          },
          {
            label: 'n. posti/box auto',
            value: extraFeatures.car_box ? extraFeatures.car_box : 'n.d.',
            icon: 'car-box',
          },
          {
            label: 'giardino privato',
            value: this.$utilities.privateGardenValue(
              extraFeatures.private_garden,
              extraFeatures.private_garden_surface,
              extraFeatures.private_garden_unit,
              'assente',
              'presente'
            ),
            icon: 'garden',
          },
        ]
      : category === 'Commerciale'
      ? [
          {
            label: 'm<sup>2</sup>',
            value: features.surface
              ? `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`
              : 'n.d.',
            icon: 'area',
          },
          {
            label: 'n. locali',
            value: features.rooms ? features.rooms : 'n.d.',
            icon: 'rooms',
          },
          {
            label: 'n. bagni',
            value: extraFeatures.toilets ? extraFeatures.toilets : 'n.d.',
            icon: 'bathroom',
          },
          {
            label: 'n. balconi/terrazzi',
            value: 'n.d. / n.d.',
            icon: 'balcony',
          },
          {
            label: 'parcheggio',
            value: extraFeatures.parking_surface
              ? `${this.$utilities.formatNumber(
                  extraFeatures.parking_surface
                )} ${unitsKeys[extraFeatures.parking_unit]}`
              : 'n.d.',
            icon: 'car-box',
          },
          {
            label: 'giardino privato',
            value: this.$utilities.privateGardenValue(
              extraFeatures.private_garden,
              extraFeatures.private_garden_surface,
              extraFeatures.private_garden_unit,
              'assente',
              'presente'
            ),
            icon: 'garden',
          },
        ]
      : category === 'Terreni'
      ? [
          {
            label: 'm<sup>2</sup>',
            value: features.surface
              ? `${this.$utilities.formatNumber(features.surface)} ${
                  unitsKeys[features.surface_unit]
                }`
              : 'n.d.',
            icon: 'area',
          },
          {
            label: 'terreno',
            value: this.$utilities.capitalize(
              typology.toLowerCase().replace('terreno ', '')
            ),
            icon: 'lands',
          },
          {
            label: 'morfologia',
            value: features.land_morphology
              ? this.$utilities.capitalize(features.land_morphology)
              : 'n.d.',
          },
          {
            label: "Cambio d'uso",
            value: extraFeatures.change_of_intended_use ? 'Sì' : 'No',
          },
          {
            label: 'Volume edificabile',
            value: extraFeatures.building_volume
              ? `${this.$utilities.roundNumber(
                  (extraFeatures.building_volume / features.surface) * 100,
                  1
                )}%`
              : 'n.d.',
          },
          {
            label: 'Volume max edificabile',
            value: extraFeatures.building_volume_max
              ? `${this.$utilities.roundNumber(
                  (extraFeatures.building_volume_max / features.surface) * 100,
                  1
                )}%`
              : 'n.d.',
          },
        ]
      : category === 'Cantieri'
      ? [
          {
            label: 'm<sup>2</sup>',
            value: features.surface_min
              ? `da ${this.$utilities.formatNumber(features.surface_min)} ${
                  unitsKeys[features.surface_unit]
                }`
              : 'n.d.',
            icon: 'area',
          },
          {
            label: 'n. locali',
            value: features.rooms_min ? `da ${features.rooms_min}` : 'n.d.',
            icon: 'rooms',
          },
          {
            label: 'n. bagni',
            value: 'n.d.',
            icon: 'bathroom',
          },
          {
            label: 'n. balconi/terrazzi',
            value: 'n.d. / n.d.',
            icon: 'balcony',
          },
          {
            label: 'box auto',
            value: 'n.d.',
            icon: 'car-box',
          },
          {
            label: 'giardino privato',
            value: 'assente',
            icon: 'garden',
          },
        ]
      : []),
  ]
}

function consultantFeatures() {
  const { consultant } = this.property

  return [
    ...((consultant.first_name && consultant.last_name) || consultant.full_name
      ? [
          {
            icon: 'person',
            label:
              consultant.first_name && consultant.last_name
                ? `${consultant.first_name} ${consultant.last_name}`
                : consultant.full_name,
            tag: 'span',
            attrs: {},
          },
        ]
      : []),
    ...(consultant.email
      ? [
          {
            icon: 'envelope-new',
            label: consultant.email,
            tag: 'a',
            attrs: {
              href: `mailto:${consultant.email}`,
              class: 'hover:underline',
            },
          },
        ]
      : []),
    ...(consultant.phone
      ? [
          {
            icon: 'phone',
            label: consultant.phone,
            tag: 'a',
            attrs: {
              href: `tel:+39${consultant.phone.trim().replace(/\s+/g, '')}`,
              class: 'hover:underline',
            },
          },
        ]
      : []),
    ...(consultant.mandate_expiry
      ? [
          {
            icon: 'calendar',
            label: `Scadenza mandato: ${new Date(
              consultant.mandate_expiry
            ).toLocaleString('it-IT', {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
            })}`,
            tag: 'span',
            attrs: {},
          },
        ]
      : []),
  ]
}

export {
  features,
  extraFeatures,
  partner,
  pdfFeatures,
  consultantFeatures,
  cardFeatures,
}
