export default {
  january: {
    name: 'ucsc-visual-january',
    ext: 'png',
  },
  'january-mobile': {
    name: 'ucsc-visual-january-mobile',
    ext: 'png',
  },
  february: {
    name: 'ucsc-visual-february',
    ext: 'png',
  },
  'february-mobile': {
    name: 'ucsc-visual-february-mobile',
    ext: 'png',
  },
  march: {
    name: 'ucsc-visual-march',
    ext: 'png',
  },
  'march-mobile': {
    name: 'ucsc-visual-march-mobile',
    ext: 'png',
  },
  april: {
    name: 'ucsc-visual-april',
    ext: 'png',
  },
  'april-mobile': {
    name: 'ucsc-visual-april-mobile',
    ext: 'png',
  },
  may: {
    name: 'ucsc-visual-may',
    ext: 'png',
  },
  'may-mobile': {
    name: 'ucsc-visual-may-mobile',
    ext: 'png',
  },
  june: {
    name: 'ucsc-visual-june',
    ext: 'png',
  },
  'june-mobile': {
    name: 'ucsc-visual-june-mobile',
    ext: 'png',
  },
  july: {
    name: 'ucsc-visual-july',
    ext: 'png',
  },
  'july-mobile': {
    name: 'ucsc-visual-july-mobile',
    ext: 'png',
  },
  august: {
    name: 'ucsc-visual-august',
    ext: 'png',
  },
  'august-mobile': {
    name: 'ucsc-visual-august-mobile',
    ext: 'png',
  },
  september: {
    name: 'ucsc-visual-september',
    ext: 'png',
  },
  'september-mobile': {
    name: 'ucsc-visual-september-mobile',
    ext: 'png',
  },
  october: {
    name: 'ucsc-visual-october',
    ext: 'webp',
  },
  november: {
    name: 'ucsc-visual-november',
    ext: 'png',
  },
  'november-mobile': {
    name: 'ucsc-visual-november-mobile',
    ext: 'png',
  },
  december: {
    name: 'ucsc-visual-december',
    ext: 'png',
  },
  'december-mobile': {
    name: 'ucsc-visual-december-mobile',
    ext: 'png',
  },
}
