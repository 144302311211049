<template>
  <div>
    <div class="pt-8 pb-4 flex">
      <OSkeleton class="mr-4" width="38px" height="38px" />
      <OSkeleton width="200px" height="40px" :rounded="false" />
    </div>
    <OSkeleton class="ml-18" width="80px" height="20px" :rounded="false" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="postcss">
@import '~/assets/css/components/skeleton';
</style>
